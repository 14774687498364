<template>
    <div class="replyList-wrapper">
        <div class="content">
            <el-breadcrumb separator=">">
                <el-breadcrumb-item :to="{ path: '/officialAccountsOrApplet' }">授权公众号</el-breadcrumb-item>
                <el-breadcrumb-item>公众号管理</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="data-statistics-wrap">
                <div class="data-statistics-title">数据统计</div>

                <div class="data-statistics-box">
                    <div class="head-portrait">
                        <img :src="headImg" />

                        <div class="head-portrait-text">{{nickName}}</div>
                    </div>

                    <div class="statistics-list">
                        <div class="statistics-item">
                            <div class="statistics-t">昨日新增粉丝</div>
                            <div v-if="is_userInfo">
                                <div class="statistics-num">{{userInfo.yesterday_new_user}}</div>
                                <div class="statistics-text">
                                    <div>对比前日</div>
                                    <div class="statistics-icon">
                                        <img v-if="userInfo.compare_new_user>=0" src="../../assets/statistics-icon.png">
                                        <img v-else src="../../assets/statistics-icon2.png">
                                    </div>
                                    <div>
                                        <span v-if="userInfo.compare_new_user>=0">+</span>
                                        {{towNumber(userInfo.compare_new_user)}} %
                                    </div>
                                </div>
                            </div>
                            <div v-else class="statistics-text">暂无数据</div>
                        </div>

                        <div class="statistics-item">
                            <div class="statistics-t">昨日取关人数</div>
                            <div v-if="is_userInfo">
                                <div class="statistics-num">{{userInfo.yesterday_cancel_user}}</div>
                                <div class="statistics-text">
                                    <div>对比前日</div>
                                    <div class="statistics-icon">
                                        <img v-if="userInfo.compare_cancel_user>=0" src="../../assets/statistics-icon.png">
                                        <img v-else src="../../assets/statistics-icon2.png">
                                    </div>
                                    <div>
                                        <span v-if="userInfo.compare_cancel_user>=0">+</span>
                                        {{towNumber(userInfo.compare_cancel_user)}} %
                                    </div>
                                </div>
                            </div>
                            <div v-else class="statistics-text">暂无数据</div>
                        </div>

                        <div class="statistics-item">
                            <div class="statistics-t">昨日净增粉丝</div>
                            <div v-if="is_userInfo">
                                <div class="statistics-num">{{userInfo.yesterday_only_new_user}}</div>
                                <div class="statistics-text">
                                    <div>对比前日</div>
                                    <div class="statistics-icon">
                                        <img v-if="userInfo.compare_only_new_user>=0" src="../../assets/statistics-icon.png">
                                        <img v-else src="../../assets/statistics-icon2.png">
                                    </div>
                                    <div>
                                        <span v-if="userInfo.compare_only_new_user>=0">+</span>
                                        {{towNumber(userInfo.compare_only_new_user)}} %
                                    </div>
                                </div>
                            </div>
                            <div v-else class="statistics-text">暂无数据</div>
                        </div>

                        <div class="statistics-item">
                            <div class="statistics-t">累积关注人数</div>
                            <div v-if="is_userInfo">
                                <div class="statistics-num">{{userInfo.yesterday_cumulate_user}}</div>
                                <div class="statistics-text">
                                    <div>对比前日</div>
                                    <div class="statistics-icon">
                                        <img v-if="userInfo.compare_cumulate_user>=0" src="../../assets/statistics-icon.png">
                                        <img v-else src="../../assets/statistics-icon2.png">
                                    </div>
                                    <div>
                                        <span v-if="userInfo.compare_cumulate_user>=0">+</span>
                                        {{towNumber(userInfo.compare_cumulate_user)}} %
                                    </div>
                                </div>
                            </div>
                            <div v-else class="statistics-text">暂无数据</div>
                        </div>

                    </div>

                </div>

            </div>
            
            <div class="set-wrap">
                <div class="data-statistics-title">设置管理</div>

                <div class="set-list">
                    <div class="set-item" @click="linkReply">
                        <div class="set-img">
                            <img src="../../assets/set-icon-1.png" alt="" srcset="">
                        </div>

                        <div class="set-text">
                            <div class="set-t">关键词回复</div>
                            <div class="set-p">设置关键词回复消息</div>
                        </div>
                    </div>
                    <div class="set-item" @click="linkPay">
                        <div class="set-img">
                            <img src="../../assets/set-icon-2.png" alt="" srcset="">
                        </div>

                        <div class="set-text">
                            <div class="set-t">关注回复</div>
                            <div class="set-p">用户关注后，可推送多条消息至用户</div>
                        </div>
                    </div>
                    <div class="set-item" @click="linkCustom">
                        <div class="set-img">
                            <img src="../../assets/set-icon-3.png" alt="" srcset="">
                        </div>

                        <div class="set-text">
                            <div class="set-t">自定义菜单</div>
                            <div class="set-p">自定义公众号菜单栏</div>
                        </div>
                    </div>
                    <div class="set-item" @click="linkNot">
                        <div class="set-img">
                            <img src="../../assets/set-icon-4.png" alt="" srcset="">
                        </div>

                        <div class="set-text">
                            <div class="set-t">消息推送</div>
                            <div class="set-p">自定义模板消息群发</div>
                        </div>
                    </div>
                    <div class="set-item" v-if="false">
                        <div class="set-img">
                            <img src="../../assets/set-icon-5.png" alt="" srcset="">
                        </div>

                        <div class="set-text">
                            <div class="set-t">专属推广码</div>
                            <div class="set-p">生成当前公众号专属推广码</div>
                        </div>
                    </div>
                    <div class="set-item" v-if="false">
                        <div class="set-img">
                            <img src="../../assets/set-icon-6.png" alt="" srcset="">
                        </div>

                        <div class="set-text">
                            <div class="set-t">客服消息群发</div>
                            <div class="set-p">仅支持推送至48小时内有互动的粉丝</div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import global from '@/assets/js/global';
    export default {
        components: {},
        data() {
            return {
                appid: this.$route.query.appid,
                nickName: this.$route.query.nickName,
                headImg: this.$route.query.headImg,
                beginDate: '',
                endDate: '',
                time: '',
                userInfo: {},
                is_userInfo: false,//判断公众数据是否有数据

            }
        },
        created() {
            this.dealTime(3);
            this.getAuthorizerUserInfo();
        },
        methods: {
            linkReply() {
                this.$router.push({
                    path: "/replyList",
                    query: {
                        appid: this.appid,
                        nickName: this.nickName,
                        headImg: this.headImg
                    }
                });
            },
            // 关注回复列表
            linkPay() {
                this.$router.push({
                    path: "/payAttentionToReplyList",
                    query: {
                        appid: this.appid,
                        nickName: this.nickName,
                        headImg: this.headImg
                    }
                });
            },
            // 新增自定义菜单
            linkCustom() {
                this.$router.push({
                    path: "/customizeTheMenuBar",
                    query: {
                        appid: this.appid,
                        nickName: this.nickName,
                        headImg: this.headImg
                    }
                });
            },
            // 消息推送列表
            linkNot() {
                this.$router.push({
                    path: "/notificationPushList",
                    query: {
                        appid: this.appid,
                        nickName: this.nickName,
                        headImg: this.headImg
                    }
                });
            },
            // 
            dealTime(v){ 
                let b = 24*60*60*1000   //一天的时间
                let day = new Date();  //当天的时间
                
                v == 3 ? day.setTime(day.getTime()-b) : v == 6 ? 
                day.setTime(day.getTime()) : day.setTime(day.getTime() + b);
                
                let dayMon = (day.getMonth() + 1) >= 10 ? day.getMonth()+1 : '0' + (day.getMonth() + 1)
                let dayDat = (day.getDate() + 1) >= 10 ? day.getDate() : '0' + (day.getDate())
                
                let s = day.getFullYear() + "-" + dayMon + "-" + dayDat;
                this.time = s;
                // console.log(this.time);
            },
            // 
            getAuthorizerUserInfo() {
                axios.get(global.wechatURL + "third/get_authorizer_user_info", {
                    params: {
                        appid: this.appid,
                        begin_date: this.time,
                        end_date: this.time
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        this.is_userInfo = true;
                        this.userInfo = res.data.data;
                        // console.log(this.userInfo)
                    } else {
                        this.is_userInfo = false;
                        this.$message({ showClose: true, message: res.data.message, type: 'error'})
                    }
                });
            },
            towNumber(val) {      
                return val.toFixed(2)    
            }

        }
    }
</script>

<style lang="less" scoped>
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
    .content {
        padding: 0 25px;
    }

    // .replyList-wrapper /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    //     color: #1A54EC;
    // }

    .data-statistics-title {
        padding: 15px 0;
        font-size: 24px;
        font-weight: bold;
        color: #252530;
        line-height: 55px;
    }
    .data-statistics-wrap {
        margin-bottom: 25px;
        .data-statistics-box {
            display: flex;
            align-items: center;
            width: 100%;
            background: #fff;
            box-shadow: 0 0 9px #e7e7e7;
            .head-portrait {
                position: relative;
                width: 225px;
                height: 225px;
                .head-portrait-text {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 45px;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(7px);
                    font-size: 16px;
                    color: #fff;
                    text-align: center;
                    line-height: 45px;
                }
            }
            .statistics-list {
                display: flex;
                align-items: center;
                width: 81%;
                .statistics-item {
                    flex: 1;
                    color: #3E3F54;
                    text-align: center;
                    .statistics-t {
                        font-size: 16px;
                        line-height: 35px;
                    }
                    .statistics-num {
                        font-size: 48px;
                        font-size: 500;
                        line-height: 85px;
                    }
                    .statistics-text {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                        line-height: 25px;
                        .statistics-icon {
                            margin: 0 3px 0 5px;
                            width: 10px;
                            height: 12px;
                        }
                    }
                }
            }
        }
    }

    .set-wrap {
        .set-list {
            display: flex;
            align-items: center;
            -justify-content: space-between;
            width: 1600px;
            .set-item {
                margin-right: 25px;
                padding: 25px 30px;
                display: flex;
                align-items: flex-start;
                width: 259px;
                height: 106px;
                background: #FFFFFF;
                box-shadow: 0 0 9px #e7e7e7;
                box-sizing: border-box;
                cursor: pointer;
                .set-img {
                    margin-right: 10px;
                    width: 56px;
                    height: 56px;
                }
                .set-text {
                    width: 66%;
                    color: #3E3F54;
                    .set-t {
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 25px;
                    }
                    .set-p {
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
        }
    }


</style>
